export default () => ({
    init() {
        let specification = document.getElementsByClassName('specification');
        let showAllBtn = document.getElementsByClassName('show-all')[0];
        showAllBtn.addEventListener('click', showAllSpecifications);

        for (let i = 0; i < specification.length; i++) {
            if (i < 5) {
                specification[i].style.display = 'flex';
            } else {
                specification[i].style.display = 'none';
                showAllBtn.style.display = 'flex';
            }
        }

        function showAllSpecifications() {
            let specifications = document.getElementsByClassName('specification');
            for (let i = 0; i < specifications.length; i++) {
                specifications[i].style.display = 'flex';
            }
            showAllBtn.style.display = 'none';
        }
    },
});
