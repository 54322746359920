import CarouselComponent from '@app/components/Carousel.js';
import DisplayableTextComponent from '@app/components/DisplayableText.js';
import UserNavComponent from '@app/components/UserNav.js';
import PinInputComponent from '@app/components/PinInput.js';
import AccordionComponent from '@app/components/Accordion.js';
import RangeInputComponent from '@app/components/RangeInput.js';
import AccordionItemComponent from '@app/components/AccordionItem.js';
import AgentContactFormComponent from '@app/components/AgentContactForm.js';
import ChatComponent from '@app/components/Chat.js';
import ContactModalComponent from '@app/components/ContactModal.js';
import CookieConsentComponent from '@app/components/CookieConsent.js';
import ConstructionProgressCarouselComponent from '@app/components/ConstructionProgressCarousel.js';
import CopyMessageComponent from '@app/components/CopyMessage.js';
import CreateAlertButtonComponent from '@app/components/CreateAlertButton.js';
import CustomSelectComponent from '@app/components/CustomSelect.js';
import DeleteAccountModalComponent from '@app/components/DeleteAccountModal.js';
import ErrorsObserverComponent from '@app/components/ErrorsObserver.js';
import FavoriteButtonComponent from '@app/components/FavoriteButton.js';
import FormButtonComponent from '@app/components/FormButton.js';
import GalleryComponent from '@app/components/Gallery.js';
import HorizontallyScrollableListComponent from '@app/components/HorizontallyScrollableList.js';
import HorizontallySwiperListComponent from '@app/components/HorizontallySwiperList.js';
import ImageSwitcherComponent from '@app/components/ImageSwitcher.js';
import FeaturedListingComponent from '@app/components/FeaturedListing.js';
import ModalComponent from '@app/components/Modal.js';
import ReportModalComponent from '@app/components/ReportModal.js';
import NotificationCardComponent from '@app/components/NotificationCard.js';
import PhoneNumberInputFieldComponent from '@app/components/PhoneNumberInputField.js';
import PhoneNumberLoaderComponent from '@app/components/PhoneNumberLoader.js';
import PopUpAuthComponent from '@app/components/PopUpAuth.js';
import PriceInputFieldComponent from '@app/components/PriceInputField.js';
import ProjectListingsBoxCarouselComponent from '@app/components/ProjectListingsBoxCarousel.js';
import RecentSearchComponent from '@app/components/RecentSearch.js';
import RefineSearchComponent from '@app/components/RefineSearch.js';
import RimuCarouselComponent from '@app/components/RimuCarousel.js';
import ShowDescriptionComponent from '@app/components/ShowDescription.js';
import ShowMenuComponent from '@app/components/ShowMenu.js';
import SimilarListingsComponent from '@app/components/SimilarListings.js';
import SplideCarouselComponent from '@app/components/SplideCarousel.js';
import SplideCarouselPaginationComponent from '@app/components/SplideCarouselPagination.js';
import TooltipComponent from '@app/components/Tooltip.js';
import VisitorIdCookieComponent from '@app/components/VisitorIdCookie.js';
import WhatsappChatComponent from '@app/components/WhatsappChat.js';
import ShowAllSpecificationsComponent from '@app/components/ShowAllSpecifications.js';
import HomeSearchTabsComponent from '@app/components/HomeSearchTabs.js';
import TagMultiSelectComponent from '@app/components/TagMultiSelect.js';
import CheckBoxMultiSelectComponent from '@app/components/CheckBoxMultiSelect.js';
import AgencySearchComponent from '@app/components/AgencySearch.js';
import FeaturedListingCarouselComponent from '@app/components/FeaturedListingCarousel.js';
import MultiSelectDropDownComponent from '@app/components/MultiSelectDropDown.js';
import MobileAppBannerModalComponent from '@app/components/MobileAppBannerModal.js';
import MobileAppLandingSwiperComponent from '@app/components/MobileAppLandingSwiper.js';
import CountdownTimer from '@app/components/CountdownTimer.js';
import CampaignCountdownTimer from '@app/components/CampaignCountdownTimer.js';
import ListingCardCarouselGalleryComponent from '@app/components/ListingCardCarouselGallery.js';
import InvoiceModalComponent from '@app/components/InvoiceModal.js';
import TermsModalComponent from '@app/components/TermsModal.js';
import SwiperGalleryComponent from '@app/components/SwiperGallery.js';
import AdvanceSearchComponent from '@app/components/AdvanceSearch.js';
import SearchResultsFilterComponent from '@app/components/SearchResultsFilter.js';
import SidebarNavComponent from '@app/components/SidebarNav.js';
import FeaturedArticlesComponent from '@app/components/FeaturedArticlesSwiper.js';
import RadialChartComponent from '@app/components/RadialChart.js';
import MapComponent from '@app/components/Map.js';
import AgentContactEnquiryModalComponent from '@app/components/AgentContactEnquiryModal.js';
import HubCoursesComponent from '@app/components/HubCourses/HubCourses.js';
import HubCoursesPaymentComponent from '@app/components/HubCourses/HubCoursesPayment.js';
import AboutImoSlidersComponent from '@app/components/AboutImoSliders.js';
import RecentSearchV2Component from '@app/components/V2/RecentSearch.js';
import NewsletterSubscribeComponent from '@app/components/V2/NewsletterSubscribe.js';
import HomeSearchTabsV2Component from '@app/components/V2/HomeSearchTabs.js';
import LocationSearchDropdownComponent from '@app/components/V2/LocationSearchDropdown.js';
import ProjectSearchDropdownComponent from '@app/components/V2/ProjectSearchDropdown.js';
import CampaignCountdownTimerV2 from '@app/components/V2/CampaignCountdownTimer.js';

window.AdvanceSearchComponent = AdvanceSearchComponent;

const Alpine = window.Alpine;
Alpine.data('CarouselComponent', CarouselComponent);
Alpine.data('DisplayableTextComponent', DisplayableTextComponent);
Alpine.data('RadialChartComponent', RadialChartComponent);
Alpine.data('UserNavComponent', UserNavComponent);
Alpine.data('PinInputComponent', PinInputComponent);
Alpine.data('AccordionComponent', AccordionComponent);
Alpine.data('RangeInputComponent', RangeInputComponent);
Alpine.data('AccordionItemComponent', AccordionItemComponent);
Alpine.data('AgentContactFormComponent', AgentContactFormComponent);
Alpine.data('ChatComponent', ChatComponent);
Alpine.data('ContactModalComponent', ContactModalComponent);
Alpine.data('CookieConsentComponent', CookieConsentComponent);
Alpine.data('ConstructionProgressCarouselComponent', ConstructionProgressCarouselComponent);
Alpine.data('CopyMessageComponent', CopyMessageComponent);
Alpine.data('CreateAlertButtonComponent', CreateAlertButtonComponent);
Alpine.data('CustomSelectComponent', CustomSelectComponent);
Alpine.data('DeleteAccountModalComponent', DeleteAccountModalComponent);
Alpine.data('ErrorsObserverComponent', ErrorsObserverComponent);
Alpine.data('FavoriteButtonComponent', FavoriteButtonComponent);
Alpine.data('FormButtonComponent', FormButtonComponent);
Alpine.data('GalleryComponent', GalleryComponent);
Alpine.data('HorizontallyScrollableListComponent', HorizontallyScrollableListComponent);
Alpine.data('HorizontallySwiperListComponent', HorizontallySwiperListComponent);
Alpine.data('ImageSwitcherComponent', ImageSwitcherComponent);
Alpine.data('FeaturedListingComponent', FeaturedListingComponent);
Alpine.data('ModalComponent', ModalComponent);
Alpine.data('ReportModalComponent', ReportModalComponent);
Alpine.data('NotificationCardComponent', NotificationCardComponent);
Alpine.data('PhoneNumberInputFieldComponent', PhoneNumberInputFieldComponent);
Alpine.data('PhoneNumberLoaderComponent', PhoneNumberLoaderComponent);
Alpine.data('PopUpAuthComponent', PopUpAuthComponent);
Alpine.data('PriceInputFieldComponent', PriceInputFieldComponent);
Alpine.data('RecentSearchComponent', RecentSearchComponent);
Alpine.data('RefineSearchComponent', RefineSearchComponent);
Alpine.data('RimuCarouselComponent', RimuCarouselComponent);
Alpine.data('ShowDescriptionComponent', ShowDescriptionComponent);
Alpine.data('ShowMenuComponent', ShowMenuComponent);
Alpine.data('SimilarListingsComponent', SimilarListingsComponent);
Alpine.data('SplideCarouselComponent', SplideCarouselComponent);
Alpine.data('SplideCarouselPaginationComponent', SplideCarouselPaginationComponent);
Alpine.data('TooltipComponent', TooltipComponent);
Alpine.data('VisitorIdCookieComponent', VisitorIdCookieComponent);
Alpine.data('WhatsappChatComponent', WhatsappChatComponent);
Alpine.data('ShowAllSpecificationsComponent', ShowAllSpecificationsComponent);
Alpine.data('HomeSearchTabsComponent', HomeSearchTabsComponent);
Alpine.data('TagMultiSelectComponent', TagMultiSelectComponent);
Alpine.data('CheckBoxMultiSelectComponent', CheckBoxMultiSelectComponent);
Alpine.data('AgencySearchComponent', AgencySearchComponent);
Alpine.data('ProjectListingsBoxCarouselComponent', ProjectListingsBoxCarouselComponent);
Alpine.data('FeaturedListingCarouselComponent', FeaturedListingCarouselComponent);
Alpine.data('MultiSelectDropDownComponent', MultiSelectDropDownComponent);
Alpine.data('MobileAppBannerModalComponent', MobileAppBannerModalComponent);
Alpine.data('MobileAppLandingSwiperComponent', MobileAppLandingSwiperComponent);
Alpine.data('CountdownTimer', CountdownTimer);
Alpine.data('CampaignCountdownTimer', CampaignCountdownTimer);
Alpine.data('ListingCardCarouselGalleryComponent', ListingCardCarouselGalleryComponent);
Alpine.data('InvoiceModalComponent', InvoiceModalComponent);
Alpine.data('TermsModalComponent', TermsModalComponent);
Alpine.data('SwiperGalleryComponent', SwiperGalleryComponent);
Alpine.data('AdvanceSearchComponent', AdvanceSearchComponent);
Alpine.data('SearchResultsFilterComponent', SearchResultsFilterComponent);
Alpine.data('SidebarNavComponent', SidebarNavComponent);
Alpine.data('FeaturedArticlesComponent', FeaturedArticlesComponent);
Alpine.data('MapComponent', MapComponent);
Alpine.data('AgentContactEnquiryModalComponent', AgentContactEnquiryModalComponent);
Alpine.data('HubCoursesComponent', HubCoursesComponent);
Alpine.data('HubCoursesPaymentComponent', HubCoursesPaymentComponent);
Alpine.data('AboutImoSlidersComponent', AboutImoSlidersComponent);
Alpine.data('RecentSearchV2Component', RecentSearchV2Component);
Alpine.data('CampaignCountdownTimerV2', CampaignCountdownTimerV2);
Alpine.data('HomeSearchTabsV2Component', HomeSearchTabsV2Component);
Alpine.data('NewsletterSubscribeComponent', NewsletterSubscribeComponent);
Alpine.data('LocationSearchDropdownComponent', LocationSearchDropdownComponent);
Alpine.data('ProjectSearchDropdownComponent', ProjectSearchDropdownComponent);
